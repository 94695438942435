import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import Layout from "../components/Layout";
import Content, { HTMLContent } from "../components/Content";

import "./TwoColumnsTemplate.scss";
import PreviewCompatibleImage from "../components/PreviewCompatibleImage";

export const MonInterventionTemplate = ({
  title,
  content,
  contentComponent,
  panelimage,
}) => {
  const PageContent = contentComponent || Content;
  return (
    <>
      <h1>{title}</h1>
      <div className="ContentElement__wrapper">
        <PageContent className="Markdown ContentElement" content={content} />
        {panelimage && (
          <PreviewCompatibleImage
            className="ContentElement ContentElement__image"
            imageInfo={{
              image: panelimage,
              alt: `Side image ${title}`,
            }}
          />
        )}
      </div>
    </>
  );
};

MonInterventionTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string,
  contentComponent: PropTypes.func,
};

const MonIntervention = ({ data = {} }) => {
  const { markdownRemark: post } = data || {};
  const { frontmatter: { title, panelimage } = {}, html } = post || {};

  return (
    <Layout>
      <section className="Layout__body">
        <MonInterventionTemplate
          contentComponent={HTMLContent}
          title={title}
          panelimage={panelimage}
          content={html}
        />
      </section>
    </Layout>
  );
};

MonIntervention.propTypes = {
  data: PropTypes.object.isRequired,
};

export default MonIntervention;

export const MonInterventionQuery = graphql`
  query MonIntervention($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
        panelimage {
          childImageSharp {
            fluid(maxWidth: 960, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`;
